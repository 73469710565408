.related-articles {
  @include breakpoint(sm-max) {
  }

  @include breakpoint(md) {
    margin-bottom: $padding-large-vertical * 7;
  }

  h2 {
    text-align: center;

    @include breakpoint(sm-max) {
      margin: 0;
    }

    @include breakpoint(md) {
      margin: 0 0 $padding-large-vertical * 4;
    }
  }
}
