.view-testimonials.view-display-id-homepage {
  .slide {
    @include breakpoint(md) {
      .slide__caption {
        padding: 0 15px;
      }

      &:nth-child(2n + 1) {
        .slide__caption {
          padding-left: 0;
        }
      }

      &:nth-child(2n + 2) {
        .slide__caption {
          padding-right: 0;
        }
      }
    }
  }

  .views-field-body {
    background-repeat: no-repeat;
    background-size: 100% auto;
    color: $gray-500;
    font-size: $font-size-medium;
    opacity: .9;
    position: relative;

    @include breakpoint(sm-max) {
      background-image: url(/sites/all/themes/electra_radix/assets/images/layout/testimonial-text-mobile.jpg);
      min-height: 356px;
      padding: $padding-large-vertical * 6 $padding-small-horizontal * 4;
    }

    @include breakpoint(md) {
      background-image: url(/sites/all/themes/electra_radix/assets/images/layout/testimonial-text.jpg);
      min-height: 315px;
      padding: 85px 65px 0;
    }

    .field-content {
      position: relative;
    }
  }

  .views-field-title,
  .views-field-field-role {
    @include breakpoint(sm-max) {
      padding-left: $padding-small-horizontal * 4;
    }

    @include breakpoint(md) {
      padding-left: 65px;
    }
  }

  .views-field-title {
    color: $gray-150;
    font-size: $font-size-small;
    font-weight: bold;
    margin: 10px 0 2px;
  }

  .views-field-field-role {
    color: $gray-500;
    font-size: $font-size-small;
  }
}
