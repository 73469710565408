.calculator-webform {
  .webform-component {
    background-color: $gray-650;
    margin: 0;
    padding: $padding-large-vertical $padding-small-horizontal * 2 $padding-large-vertical;

    @include breakpoint(md) {
      align-items: center;
      display: flex;
      padding: $padding-large-vertical $padding-small-horizontal * 8 $padding-large-vertical $padding-small-horizontal * 7;
    }

    label {
      display: block;
      font-size: $font-size-medium;
      font-weight: normal;
      margin: 0 0 $padding-large-vertical;

      @include breakpoint(md) {
        margin-right: $padding-small-horizontal * 4;
        width: calc(50% - 100px);
      }
    }

    > .form-control,
    .form-textarea-wrapper {
      border-width: 0;
      color: $gray-500;
      font-size: $font-size-medium;
      height: auto;
      padding: $padding-large-vertical $padding-small-horizontal * 1.5;

      @include breakpoint(md) {
        padding: $padding-large-vertical $padding-small-horizontal * 2;
        width: calc(50% + 100px - #{$padding-small-horizontal * 4});
      }
    }

    .form-textarea-wrapper {
      padding: 0;
    }

    textarea {
      border-width: 0;
    }

    .grippie {
      display: none;
    }

    &--pasul-1,
    &--pasul-2,
    &--pasul-3 {
      display: block;

      @include breakpoint(xs) {
        padding-bottom: $padding-large-vertical * 2.5 0 0;
      }

      @include breakpoint(sm) {
        padding-bottom: $padding-large-vertical * 3.5;
        padding-top: $padding-large-vertical * 6;
      }

      h2 {
        color: $body-bg;
        line-height: 1;
        margin: 0;

        @include breakpoint(xs) {
          font-size: $font-size-large * 3;
        }

        @include breakpoint(sm) {
          font-size: $font-size-h2 * 2;
        }
      }

      div {
        font-size: $font-size-h2;
        line-height: 1;
      }
    }
  }

  .webform-component--required-step-1,
  .webform-component--required-step-2 {
    @include breakpoint(md) {
      font-size: $font-size-small;
      justify-content: flex-end;
    }

    p {
      margin-bottom: 0;

      @include breakpoint(md) {
        width: calc(50% + 100px - 40px);
      }
    }
  }

  .form-actions {
    background-color: $gray-650;
    margin: 0;
    padding: $padding-large-vertical * 1.5;

    @include breakpoint(md) {
      display: flex;
      justify-content: flex-end;
      padding: $padding-large-vertical $padding-small-horizontal * 8 $padding-large-vertical * 6.5 $padding-small-horizontal * 7;
    }

    .webform-previous {
      display: none;
    }

    input {
      border-radius: 30px;
      border-width: 0;
      background: $brand-primary;
      color: $body-bg;
      font-weight: bold;
      height: 60px;
      margin: 0;
      padding: $padding-large-vertical $padding-small-horizontal * 2;

      @include breakpoint(md) {
        width: calc(50% + 100px - #{$padding-small-horizontal * 4});
      }
    }
  }

  .col-md-8 {
    z-index: 1;
  }

  .col-md-4 {
    color: $body-bg;
    font-size: $font-size-medium;
    margin-top: -450px;
    opacity: .9;
    padding-top: 450px;
    position: relative;

    &:before {
      background-color: $brand-primary;
      bottom: 0;
      clip-path: polygon(0 450px, 100% 0, 100% 100%, 0 100%);
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    @include breakpoint(md) {
      display: flex;
      justify-content: center;
      margin-top: 50%;
      padding: 0 15px 0 $padding-small-horizontal * 7.5;

      &:before {
        display: none;
      }
    }
  }

  .webform-component--html-rezultat {
    display: block;

    .summary {
      @include breakpoint(md) {
        display: flex;
      }
    }

    .required {
      color: $brand-primary;
    }

    .price {
      font-size: $font-size-medium;
      margin-bottom: $padding-large-vertical * 2.5;

      @include breakpoint(md) {
        flex-grow: 3;
        margin-bottom: 0;
        margin-right: $padding-large-vertical * 6;
      }
    }

    .total-price {
      border-bottom: 1px solid $gray-200;
      padding-bottom: 17px;

      div {
        color: $brand-primary;
        font-size: $font-size-h2;
        font-weight: bold;
      }
    }

    .posts {
      align-items: flex-end;
      display: flex;
      justify-content: space-around;

      @include breakpoint(md) {
        flex-grow: 2;
      }

      img {
        display: block;
        height: auto;
        max-width: 100%;
      }
    }

    .posts-price,
    .detailed-price {
      margin-top: 24px;

      div {
        color: $brand-primary;
        font-size: $font-size-large;
        font-weight: bold;
      }

      .per-apartment {
        color: $gray-500;
        font-size: $font-size-small;
      }
    }

    .details-container {
      margin-top: $padding-large-vertical * 6.5;

      @include breakpoint(sm) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .details-item {
      margin-bottom: $padding-large-vertical * 3.5;

      @include breakpoint(sm) {
        width: 50%;

        &:nth-child(2n) {
          padding-left: $padding-base-horizontal * 6.5;
        }
      }

      .icon {
        align-items: center;
        background-color: $brand-primary;
        border-radius: 50%;
        color: $body-bg;
        display: flex;
        height: 41px;
        justify-content: center;
        width: 41px;
      }

      .title {
        font-size: $font-size-large;
        font-weight: bold;
        margin: 4px 0 6px;
      }

      .details {
        color: $gray-500;
        font-size: $font-size-small;
      }
    }

    .mention {
      color: $gray-500;
      font-size: $font-size-small;
    }
  }

  .webform-component--want-offer {
    h2 {
      margin: 0;

      @include breakpoint(xs) {
        font-size: $font-size-h3;
      }
    }
  }

  .field-name-body {
    padding: $padding-large-vertical * 4 0 $padding-large-vertical * 10;
    position: relative;

    @include breakpoint(md) {
      margin-top: calc(-50% - 175px);
      padding: 0;
    }
  }
}

.calculator-progressbar {
  @include breakpoint(sm-max) {
    display: none;
  }

  @include breakpoint(md) {
    border-bottom: 4px solid $gray-600;
    display: flex;
    justify-content: space-between;
    margin-bottom: $padding-large-vertical * 8.5;
    position: relative;
  }

  .calculator-step-2 & {
    &:after {
      background-color: $brand-primary;
      bottom: -4px;
      content: '';
      display: block;
      height: 4px;
      left: 0;
      position: absolute;
      right: 50%;
    }
  }

  .page {
    color: $gray-500;
    padding-bottom: $padding-large-vertical * 3.5;
    position: relative;

    &:after {
      background-color: $gray-600;
      border-radius: 50%;
      bottom: -14px;
      content: '';
      display: block;
      height: 25px;
      position: absolute;
      width: 25px;
    }

    &.current,
    &.completed {
      font-weight: bold;

      &:after {
        background-color: $brand-primary;
      }
    }

    &.page-1 {
      &:after {
        left: 0;
      }
    }

    &.page-2 {
      &:after {
        left: calc(50% - 12.5px);
      }
    }

    &.page-3 {
      &:after {
        right: 0;
      }
    }
  }

  .page-2 {
    text-align: center;
  }

  .page-3 {
    text-align: right;
  }

  i {
    color: $brand-primary;
    font-size: $font-size-medium;
    margin-left: $padding-xs-horizontal;

    & + div {
      color: $gray-150;
      font-size: $font-size-large;
    }
  }
}

.page-node-done {
  .calculator-progressbar {
    border-bottom-color: $brand-primary;
  }

  .after-content-2cols {
    @include breakpoint(md) {
      padding: 0 0 $padding-large-vertical * 4;
    }
  }

  .webform-confirmation {
    align-items: center;
    background-color: $gray-650;
    display: flex;
    flex-direction: column;
    text-align: center;

    @include breakpoint(xs) {
      padding: $padding-large-vertical * 3.5 0 $padding-large-vertical * 9.5;
    }

    @include breakpoint(sm) {
      padding: $padding-large-vertical * 7.5 0 $padding-large-vertical * 9.5;
    }

    h2 {
      margin: 0;
      max-width: 500px;
    }

    p {
      color: $gray-500;
      font-size: $font-size-medium;
      margin-top: 7px;
      max-width: 333px;
    }

    i {
      color: $brand-primary;
      display: block;
      font-size: $font-size-large * 4;
      margin-top: 24px;
    }
  }

  .links {
    display: none;
  }
}
