.contact-form {
  .field-name-body {
    color: $gray-400;
    font-size: $font-size-medium;
    margin-bottom: $padding-large-vertical * 3.5;
    opacity: .9;
    text-align: center;
  }

  form {
    padding: $padding-large-vertical $padding-base-horizontal * 2;
  }
}
