.header {
  .container {
    @include breakpoint(sm-max) {
      padding: 0;
    }
  }
}

.navbar-default {
  @include breakpoint(sm-max) {
    border-width: 0;
    margin-bottom: 0;
  }

  @include breakpoint(md) {
    align-content: center;
    background-color: transparent;
    border-width: 0;
    display: flex;
    margin-bottom: 0;
    min-height: 90px;
  }

  .navbar-toggle {
    @include breakpoint(sm-max) {
      border-width: 0;
      margin: 26px 20px 27px 0;
    }

    .icon-bar {
      @include breakpoint(sm-max) {
        background-color: $gray-200;
        border-radius: 0;
        height: 3px;
        width: 25px;
      }

      & + .icon-bar {
        @include breakpoint(sm-max) {
          margin-top: 5px;
        }
      }
    }
  }

  .menu.nav {
    @include breakpoint(sm-max) {
      margin: 7px -15px;
    }

    @include breakpoint(md) {
      display: flex;
      float: none;
    }

    > li {
      > a {
          color: $gray-300;
      }
    }
  }
}

.navbar-brand {
  @include breakpoint(sm-max) {
    display: block;
    float: none;
    height: auto;
    padding: $padding-large-vertical * 1.5 $padding-small-horizontal * 2;
  }

  @include breakpoint(md) {
    height: 90px;
    margin: $padding-extra-large-vertical * 2 $padding-extra-large-vertical * 4 $padding-extra-large-vertical * 2 0;
    padding: 0;
  }

  #logo {
    margin-right: 0;

    @include breakpoint(sm-max) {
      float: none;
      height: 60px;
    }
  }
}

.navbar-collapse.collapse {
  @include breakpoint(md) {
    align-items: center;
    display: flex !important;
    padding: 0;
    width: 100%;
  }
}

#main-menu {
  > li {
    > a {
      @include breakpoint(md) {
        padding: $padding-extra-large-vertical;
      }
    }
  }
}

.region-menu-right {
  @include breakpoint(md) {
    margin-left: auto;
  }

  .menu.nav {
    > li {
      > a {
        @include breakpoint(md) {
          align-items: center;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 19px auto;
          border-radius: 50%;
          display: flex;
          font-size: 0;
          height: 60px;
          justify-content: center;
          margin-left: $padding-medium-horizontal;
          padding: 2 * $padding-large-vertical 2 * $padding-base-horizontal $padding-extra-large-vertical;
          width: 60px;
        }
      }

      &.first {
        > a {
          @include breakpoint(md) {
            border-radius: 30px;
            background-color: $gray-700;
            font-size: $font-size-base;
            margin-left: 0;
            width: auto;
          }
        }
      }

      &:nth-child(2) {
        > a {
          @include breakpoint(md) {
            background-color: $gray-250;
            background-image: url('/sites/all/themes/electra_radix/assets/images/icon/user.png');
          }
        }
      }

      &.last {
        > a {
          @include breakpoint(md) {
            background-color: $brand-primary;
            background-image: url('/sites/all/themes/electra_radix/assets/images/icon/cart.png');
          }
        }
      }
    }
  }
}
