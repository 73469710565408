.view-clone-of-sisteme-depth-2-pt-ceilalti {
  .view-header {
    margin: $padding-large-vertical * 2.5 0;
  }

  .row {
    flex-wrap: wrap;

    @include breakpoint(sm-max) {
      flex-direction: row;
    }
  }

  .col-md-3 {
    @include breakpoint(sm-max) {
      margin-bottom: $padding-large-vertical * 3;
    }

    @include breakpoint(md) {
      margin-bottom: $padding-large-vertical * 8;
    }
  }
}
