.bluelink-access-homepage {
  p {
    color: $gray-400;
    font-size: $font-size-medium;
    margin: 17px 0 24px;
    opacity: .9;
  }

  .bottom {
    align-items: center;
    display: flex;
  }

  a {
    @include red-button;

    @include breakpoint(md) {
      margin: 15px 0 160px 0;
    }
  }

  i {
    color: $black;
    font-size: $font-size-base * 2;
    margin-left: 20px;
    opacity: .2;
  }
}
