.taxonomy-term {
  &.term-sisteme--full {
    .container {
      @include breakpoint(sm-max) {
      }

      @include breakpoint(md) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    .field-name-field-servicii {
      font-size: $font-size-xsmall;

      @include breakpoint(md) {

        background-color: $gray-650;
        padding: $padding-base-horizontal * 3;
      }
    }

    .taxonomy-term-description {
      margin: $padding-large-vertical * 4 0 $padding-large-vertical * 6;
    }
  }

  &.term-sisteme--sidebar {
    a {
      font-size: $font-size-large;

      @include breakpoint(sm-max) {
        background-color: $gray-700;
        border-radius: 30px;
        color: $gray-300;
        display: block;
        font-weight: bold;
        padding: $padding-extra-large-vertical 0;
        text-align: center;
      }

      @include breakpoint(md) {
        align-items: center;
        color: $body-bg;
        display: flex;
        justify-content: space-between;
        padding: $padding-base-vertical 0;
      }

      &:hover {
        font-weight: bold;
      }
    }

    i {
      @include breakpoint(sm-max) {
        display: none;
      }

      @include breakpoint(md) {
        float: right;
        font-size: $font-size-small;
      }
    }
  }

  &.term-sisteme--main {
    background-color: $gray-700;

    @include breakpoint(md) {
      padding: $padding-large-vertical * 4;
    }

    .row {
      @include breakpoint(sm-max) {
        flex-direction: column-reverse;
        text-align: center;
      }

      @include breakpoint(md) {
        align-items: center;
      }
    }

    img {
      height: auto;
      max-width: 100%;
      width: auto;

      @include breakpoint(sm-max) {
        margin-bottom: $padding-large-vertical * 3;
        max-height: 175px;
      }

      @include breakpoint(md) {
        max-height: 290px;
      }
    }

    .field-name-field-titlu-homepage {
      font-weight: bold;

      @include breakpoint(sm-max) {
        font-size: $font-size-base * 2;
        margin: $padding-large-vertical * 3.5 0 $padding-large-vertical;
      }

      @include breakpoint(md) {
        font-size: $font-size-h2;
        margin-bottom: $padding-large-vertical;
      }
    }

    .field-name-field-imagine-titlu {
      text-align: center;
    }

    a {
      color: $gray-150;
    }

    .field-name-field-subtitlu-homepage {
      font-size: $font-size-medium;

      @include breakpoint(sm-max) {
        margin-bottom: $padding-large-vertical * 3;
      }
    }
  }
}

.term-listing-heading + p {
  display: none;
}
