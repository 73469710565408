.block--documente-categorie,
.block--documente-vechi-categorie {
  @include breakpoint(sm-max) {
    margin-bottom: $padding-large-vertical * 3;
  }

  @include breakpoint(md) {
    margin-bottom: $padding-large-vertical * 8;
  }

  h2 {
    font-size: $font-size-h3;
    margin-top: 0;
  }
}
