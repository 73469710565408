.view-sisteme-depth-1-eu {
  &.view-display-id-sub_categories {
    padding: $padding-base-horizontal * 3;

    @include breakpoint(md) {
      background-color: $brand-primary;
    }

    .container {
      @include breakpoint(sm-max) {
        padding: 0;
      }
    }


    .row {
      @include breakpoint(sm-max) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .col-md-12 {
      @include breakpoint(sm-max) {
        padding: 0;
        width: 45%;
      }
    }
  }

  &.view-display-id-sub_categories_main {
    .col-md-12 {
      @include breakpoint(sm-max) {
        margin-top: $padding-extra-large-vertical * 2;
      }

      @include breakpoint(md) {
        margin-bottom: $padding-extra-large-vertical * 2;
      }
    }
  }
}

