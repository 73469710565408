.help-homepage {
  @include breakpoint(sm-max) {
    margin: $padding-large-vertical * 4 0;
  }

  @include breakpoint(md) {
    margin-bottom: 80px;
    text-align: center;
  }

  h2 {
    margin: 0;
  }

  .row {
    @include breakpoint(md) {
      justify-content: center;
    }
  }

  .col-md-5 {
    @include breakpoint(md) {
      padding: 0;
    }
  }

  p {
    color: $gray-500;
    font-size: $font-size-medium;
    margin: 18px 0 37px;
    opacity: .9;
  }

  .buttons {
    @include breakpoint(sm-max) {
      display: flex;
      justify-content: space-between;
    }
  }

  .btn-gray {
    @include gray-button;
  }

  .btn-primary {
    @include red-button;
  }
}
