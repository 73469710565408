.homepage-banner {
  color: $body-bg;

  @include breakpoint(sm-max) {
    margin-bottom: $padding-large-vertical * 4;
  }

  @include breakpoint(md) {
    margin-bottom: $padding-large-vertical * 10;
  }

  .homepage-banner-container {
    @include breakpoint(md) {
      align-items: flex-end;
      background-image: url('/sites/all/themes/electra_radix/assets/images/layout/home-banner.jpg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      display: flex;
      margin-left: auto;
      max-width: calc(50% + 1262px / 2 - 15px);
      min-height: 626px;
    }
  }

  .container {
    @include breakpoint(md) {
      margin-left: 0;
      margin-bottom: -50px;
      padding-right: 30px;
    }
  }

  .row {
    @include breakpoint(sm-max) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .col-md-6 {
    @include breakpoint(sm-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .block__content {
    padding: 2 * $padding-large-horizontal 3 * $padding-large-horizontal 5 * $padding-small-horizontal;

    background-color: $brand-primary;

    > * {
      opacity: .9;
    }

    a {
      border-top: 1px solid $body-bg;
      display: inline-block;
      color: $body-bg;
      font-size: $font-size-small;
      margin-top: 3 * $padding-large-horizontal;
      padding-top: $padding-base-vertical;
      text-transform: uppercase;
    }
  }

  img {
    max-width: 100%;

    @include breakpoint(md) {
      display: none;
    }
  }

  h1 {
    margin: 0 0 $padding-large-vertical;
  }

  p {
    font-size: $font-size-medium;
    margin-bottom: 0;

    @include breakpoint(sm-max) {
      max-width: 90%;
    }
  }
}
