.container {
  padding-left: 20px;
  padding-right: 20px;
}

.page-blog {
  .region-banner + .container {
    @include breakpoint(sm-max) {
      padding: 0;
    }
  }
}

.node-type-article {
  #content + .container {
    @include breakpoint(sm-max) {
      padding: 0;
    }

    .row {
      @include breakpoint(sm-max) {
        margin: 0;
      }
    }

    .col-md-12 {
      @include breakpoint(sm-max) {
        padding: 0;
      }
    }
  }
}
