.row {
  display: flex;

  @include breakpoint(sm-max) {
    flex-direction: column;
  }

  @include breakpoint(md) {
    flex-wrap: wrap;
  }
}

.row > [class*='col-'] {
  @include breakpoint(md) {
    display: flex;
    flex-direction: column;
  }
}

.page-header {
  border-bottom-width: 0;
}
