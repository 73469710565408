.view-blog {
  &.view-display-id-homepage {
    > .container > .row {
      @include breakpoint(xs) {
        flex-direction: row;
      }
    }

    .col-md-12 {
      &:not(:last-child) {
        margin-bottom: $padding-large-horizontal * 2;
      }
    }
  }

  &.view-display-id-featured {
    .row {
      @include breakpoint(sm-max) {
        margin: 0;
      }
    }

    .col-md-12 {
      @include breakpoint(sm-max) {
        padding: 0;
      }
    }
  }

  &.view-display-id-page {
    .container {
      @include breakpoint(sm-max) {
        margin: 0;
        padding: 0;
      }
    }

    .row {
      @include breakpoint(sm-max) {
        margin: 0;
      }
    }

    .col-md-4 {
      @include breakpoint(sm-max) {
        background-color: $gray-650;
        padding: 0;
      }

      @include breakpoint(md) {
        margin-top: $padding-large-vertical * 7;
      }

      &:first-child {
        @include breakpoint(sm-max) {
          margin-top: $padding-large-vertical * 4;
        }
      }
    }
  }

  &.view-display-id-related {
    .container {
      @include breakpoint(sm-max) {
        margin: 0;
        padding: 0;
      }
    }

    .row {
      @include breakpoint(sm-max) {
        margin: 0;
      }
    }

    .col-md-4 {
      @include breakpoint(sm-max) {
        background-color: $gray-650;
        padding: 0;
      }

      @include breakpoint(md) {
        margin-top: $padding-large-vertical * 7;
      }

      &:first-child {
        @include breakpoint(sm-max) {
          margin-top: $padding-large-vertical * 4;
        }
      }
    }
  }
}
