.addtoany_list {
  display: none;
}

.node-article {
  img {
    height: auto;
    max-width: 100%;
  }

  .ext {
    display: none;
  }

  &.node-homepage {
    h4 {
      font-weight: bold;
      margin: 0 0 8px;

      @include breakpoint(sm-max) {
        margin-top: $padding-base-vertical * 2;
      }

      a {
        color: $gray-150;
      }
    }

    .field-name-body {
      color: $gray-400;
      font-size: $font-size-small;
      opacity: .9;
    }
  }

  &.node-featured {
    @include breakpoint(sm-max) {
      background-color: $gray-650;
    }

    @include breakpoint(md) {
      background: $body-bg;
      background: linear-gradient(90deg, $body-bg 0%, $body-bg 50%, $gray-650 50%, $gray-650 100%);
    }

    .container {
      @include breakpoint(sm-max) {
        padding: 0;
      }
    }

    .col-md-4,
    .col-md-8 {
      @include breakpoint(sm-max) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .col-md-8 {
      @include breakpoint(sm-max) {
        padding: 0;
      }
    }

    .col-md-4 {
      @include breakpoint(sm-max) {
        padding-bottom: $padding-large-vertical * 4;
      }
      @include breakpoint(md) {
        padding-left: 35px;
      }
    }

    h2 {
      @include breakpoint(sm-max) {
        font-size: $font-size-base * 1.625;
        margin: $padding-large-vertical * 3 0 $padding-large-vertical * 2;
      }

      @include breakpoint(md) {
        margin: $padding-large-vertical * 4 0 $padding-large-vertical * 3;
      }

      a {
        color: $gray-200;
      }
    }

    .field-name-body {
      color: $gray-400;
      font-size: $font-size-medium;
    }
  }

  &.node-teaser {
    h3,
    .field-name-body,
    .date-info {
      @include breakpoint(sm-max) {
        padding-left: $padding-small-horizontal * 2;
        padding-right: $padding-small-horizontal * 2;
      }
    }

    h3 {
      font-size: $font-size-base * 1.375;
      margin: 34px 0 13px;

      a {
        color: $gray-200;
      }
    }

    .field-name-body {
      color: $gray-400;
      font-size: $font-size-medium;

      @include breakpoint(sm-max) {
        padding-bottom: $padding-large-vertical * 2;
      }
    }

    .date-info {
      @include breakpoint(sm-max) {
        margin: 0;
        padding-bottom: $padding-large-vertical * 3.5;
      }

      @include breakpoint(md) {
        margin: $padding-large-vertical * 4.5 0 0;
      }
    }
  }

  &.node-full {
    @include breakpoint(md) {
      margin-top: 14px;
    }

    .container {
      @include breakpoint(sm-max) {
        padding: 0;
      }
    }

    .row {
      @include breakpoint(sm-max) {
        margin: 0;
      }
    }

    .col-md-8 {
      @include breakpoint(sm-max) {
        padding: 0;
      }

      &.col-md-offset-2 {
        @include breakpoint(sm-max) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }

    .col-md-4 {
      @include breakpoint(md) {
        padding-left: 35px;
      }
    }

    h1 {
      @include breakpoint(sm-max) {
        font-size: $font-size-h3;
        margin: 21px 0 27px;
      }

      @include breakpoint(md) {
        font-size: $font-size-h2;
        margin: 50px 0 27px;
      }
    }

    .field-name-body {
      color: $gray-500;
      font-size: $font-size-medium;
      overflow: hidden;

      @include breakpoint(sm-max) {
        margin: 0 0 $padding-large-vertical * 3;
      }

      @include breakpoint(md) {
        margin: $padding-large-vertical * 6 0 $padding-large-vertical * 5;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .addtoany_list {
      align-items: center;
      color: $gray-500;
      font-size: $font-size-small;

      @include breakpoint(sm-max) {
        display: block;
        margin-bottom: $padding-large-vertical * 3.75;
      }

      @include breakpoint(md) {
        display: flex;
        margin-bottom: $padding-large-vertical * 7.5;
      }

      a {
        padding: 0;

        @include breakpoint(sm-max) {
          margin-left: 14px;
        }

        @include breakpoint(md) {
          margin-left: 34px;
        }
      }
    }
  }
}

.user-info {
  @include breakpoint(sm-max) {
    margin-bottom: $padding-large-vertical * 2;
  }

  @include breakpoint(md) {
    margin-bottom: $padding-large-vertical * 3;
  }

  img {
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    margin-right: $padding-small-horizontal;
    width: 40px;
  }
}

.date-info {
  color: $gray-500;
  display: flex;
  font-size: $font-size-small;
  margin-bottom: $padding-large-vertical * 3;

  .date {
    margin-right: $padding-small-horizontal * 4.5;
  }
}

