.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-dots {
  font-size: 0;
  margin-top: $padding-large-vertical * 4;
  position: static;

  li {
    height: 12px;
    margin: 0 4px;
    width: 12px;

    &.slick-active {
      button {
        background-color: $brand-primary;
        border-color: $brand-primary;
      }
    }

    button {
      border: 1px solid $gray-400;
      border-radius: 50%;
      font-size: 0;
      height: 12px;
      margin: 0;
      padding: 4px;
      width: 12px;

      &:before {
        display: none;
      }
    }
  }
}

.slick__arrow {
  @include breakpoint(xs) {
    left: -14px;
    right: -14px;
    width: auto;
  }

  button {
    height: 40px;
    width: 40px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1;

    @include breakpoint(xs) {
      height: 28px;
      width: 28px;
    }

    &:hover {
      &:before {
        color: $body-bg;
        opacity: .64;
      }
    }

    &:before {
      align-items: center;
      background-color: $gray-100;
      border-radius: 50%;
      color: $body-bg;
      display: flex;
      font-family: inherit;
      font-size: $font-size-base * 1.375;
      height: 40px;
      justify-content: center;
      opacity: .44;
      width: 40px;

      @include breakpoint(xs) {
        font-size: $font-size-base;
        height: 28px;
        width: 28px;
      }
    }
  }

  .slick-prev {
    &:before {
      content: '\f053';
    }
  }

  .slick-next {
    &:before {
      content: '\f054';
    }
  }
}
