.block-views--testimonials-homepage {
  padding: 0 0 40px;

  h2 {
    margin: 0;
    @include breakpoint(sm-max) {
      margin-bottom: $padding-medium-horizontal * 2;
    }

    @include breakpoint(md) {
      margin-bottom: $padding-medium-horizontal * 4;
      text-align: center;
    }
  }
}
