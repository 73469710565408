.view-sisteme-electra {
  &.view-display-id-homepage {
    text-align: center;

    .col-md-3 {
      &:not(:last-child) {
        @include breakpoint(sm-max) {
          margin-bottom: $padding-large-vertical * 3;
        }
      }
    }

    .views-field-field-imagine-homepage {
      margin-bottom: $padding-medium-horizontal * 2;

      &:before {
        background-color: $body-bg;
        border-radius: 50%;
        bottom: 0;
        content: '';
        height: 168px;
        left: calc(50% - 84px);
        position: absolute;
        right: calc(50% - 84px);
        top: 0;
        width: 168px;
      }

      .field-content {
        position: relative;
      }
    }

    img {
      display: block;
      margin: 0 auto;
      max-height: 168px;
      width: auto;
    }

    .views-field-field-titlu-homepage {
      a {
        color: $gray-150;
        font-size: $font-size-large;
        font-weight: bold;
        margin-bottom: $padding-base-vertical;
      }
    }

    .views-field-field-subtitlu-homepage {
      color: $gray-400;
      opacity: .9;
    }
  }
}
