.about-us-homepage {
  @include breakpoint(sm-max) {
    margin-top: $padding-large-vertical * 3;
    padding-bottom: $padding-large-vertical * 3;
  }

  @include breakpoint(md) {
    padding: $padding-large-vertical * 8 0 $padding-large-vertical * 8;
  }

  .col-md-5 {
    @include breakpoint(xs) {
      margin-left: $padding-small-horizontal * 2;
      margin-right: $padding-small-horizontal * 2;
      padding: 0;
    }

    &:first-child {
      background-image: url(/sites/all/themes/electra_radix/assets/images/layout/electrainstal-buzau.jpg);
      background-position: bottom left;
      background-repeat: no-repeat;
      color: $gray-400;
      padding-top: $padding-base-horizontal * 2;

      @include breakpoint(xs) {
        min-height: 590px;
        text-align: left;
      }

      @include breakpoint(md) {
        min-height: 538px;
        opacity: .9;
        text-align: right;
      }

      span {
        color: $brand-primary;
        display: inline-block;
        margin-top: -22px;

        @include breakpoint(sm-max) {
          font-size: $font-size-large * 6;
          text-align: center;
        }

        @include breakpoint(sm) {
          font-size: $font-size-large * 7;
        }
      }
    }

    &:last-child {
      align-self: center;
    }
  }

  p {
    color: $gray-400;
    font-size: $font-size-medium;
    margin: 16px 0 25px;
    opacity: .9;
  }

  a {
    @include red-button;
  }
}
