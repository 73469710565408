.goals {
  background-color: $gray-650;

  @include breakpoint(sm-max) {
    margin-bottom: $padding-large-vertical * 4;
    padding: $padding-large-vertical * 4 0;
  }

  @include breakpoint(md) {
    margin-bottom: $padding-large-vertical * 8;
    padding: $padding-large-vertical * 8 0;
  }

  .row {
    @include breakpoint(xs) {
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .col-sm-4 {
    text-align: center;

    @include breakpoint(sm-max) {
      &:first-child {
        margin-bottom: $padding-large-vertical * 4;
        width: 100%;
      }

      &:not(:first-child) {
        width: 50%;
      }
    }
  }

  i {
    color: $body-bg;
    margin: 0 auto;

    &:before {
      align-items: center;
      background-color: $brand-primary;
      border-radius: 50%;
      display: flex;
      font-size: $font-size-h1;
      height: 122px;
      justify-content: center;
      width: 122px;
    }
  }

  h3 {
    color: $gray-150;
    font-size: $font-size-large;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }
}
