.view-produse-similare {
  &.view-display-id-related {
    text-align: center;

    .slide__caption {
      @include breakpoint(xs) {
        padding: 0 30px 20px;
      }

      @include breakpoint(sm) {
        padding: 17px 30px;
      }
    }

    .slide__description {
      a {
        &:focus {
          outline: none;
        }

        &:first-child {
          align-items: center;
          display: flex;

          @include breakpoint(xs) {
            min-height: 215px;
          }

          @include breakpoint(sm) {
            min-height: 250px;
          }

          img {
            @include breakpoint(xs) {
              max-height: 165px;
            }

            @include breakpoint(sm) {
              max-height: 215px;
            }
          }
        }

        &:last-child {
          color: $gray-200;
          font-size: $font-size-medium;
        }
      }
    }

    .views-field-field-imagine-homepage {
      margin-bottom: $padding-medium-horizontal * 2;

      &:before {
        background-color: $body-bg;
        border-radius: 50%;
        bottom: 0;
        content: '';
        height: 168px;
        left: calc(50% - 84px);
        position: absolute;
        right: calc(50% - 84px);
        top: 0;
        width: 168px;
      }

      .field-content {
        position: relative;
      }
    }

    img {
      display: block;
      margin: 0 auto;
      max-height: 168px;
      width: auto;
    }

    .views-field-field-titlu-homepage {
      a {
        color: $gray-150;
        font-size: $font-size-large;
        font-weight: bold;
        margin-bottom: $padding-base-vertical;
      }
    }

    .views-field-field-subtitlu-homepage {
      color: $gray-400;
      opacity: .9;
    }
  }
}
