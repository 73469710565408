#footer {
  background-color: $gray-100;
  color: $body-bg;
  margin-bottom: 0;
  padding: 6 * $padding-large-vertical 0;

  @include breakpoint(sm-max) {
    border-width: 0;
    padding: $padding-large-vertical * 3 $padding-small-horizontal;
  }

  .row:last-child {
    margin-top: 3 * $padding-large-vertical;

    @include breakpoint(md) {
      align-items: center;
    }

    ul {
      margin-bottom: 0;
    }

    .col-md-1 {
      opacity: .5;
    }
  }

  a {
    color: $body-bg;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      background-color: transparent;
    }
  }

  .col-md-8 {
    @include breakpoint(sm-max) {
      margin-top: $padding-large-vertical * 3;
    }
  }

  .col-md-1 {
    @include breakpoint(sm-max) {
      float: right;
      margin-top: -37px;
    }
  }
}

.social_media_links {
  align-items: center;
  display: flex;
  font-size: 0;
  padding-left: 0;

  li {
    &:not(:first-child) {
      margin-left: 3 * $padding-small-horizontal;
    }
  }

  a {
    background-size: 100% 100%;
    display: block;
  }

  .facebook {
    a {
      background-image: url('/sites/all/themes/electra_radix/assets/images/icon/facebook.png');
      height: 24px;
      width: 13px;
    }
  }

  .twitter {
    a {
      background-image: url('/sites/all/themes/electra_radix/assets/images/icon/twitter.png');
      height: 20px;
      width: 24px;
    }
  }

  .youtube {
    a {
      background-image: url('/sites/all/themes/electra_radix/assets/images/icon/youtube.png');
      height: 18px;
      width: 27px;
    }
  }

  .whatsapp {
    a {
      background-image: url('/sites/all/themes/electra_radix/assets/images/icon/whatsapp.png');
      height: 22px;
      width: 21px;
    }
  }
}

.addresses {
  list-style-type: none;
  opacity: .9;
  padding-left: 0;

  li {
    line-height: $line-height-large;
    margin-top: 2 * $padding-large-vertical;

    > :first-child {
      cursor: pointer;

      i {
        margin-left: $padding-large-horizontal;
      }
    }

    > :not(:first-child) {
      opacity: .5;
    }

    i {
      @include breakpoint(sm-max) {
        float: right;
      }
    }
  }

  .address {
    i {
      text-align: center;
      width: 25px;
    }
  }

  a {
    color: $body-bg;
  }

  .mailto {
    display: none;
  }
}

.region-before-footer-right .nav:first-child,
.region-footer-right .nav {
  display: flex;
  justify-content: space-between;

  @include breakpoint(sm-max) {
    flex-direction: column;
  }

  &:before {
    content: none;
  }
}

.region-before-footer-right {
  .nav:first-child {
    > li {
      &.open {
        ul {
          @include breakpoint(sm-max) {
            display: block;
          }
        }
      }

      &:not(:first-child) {
        @include breakpoint(sm-max) {
          margin-top: $padding-base-vertical * 2;
        }
      }

      > a {
        margin-bottom: $padding-large-horizontal / 2;
      }
    }

    ul {
      margin-left: 0;
      opacity: .5;

      @include breakpoint(sm-max) {
        display: none;
      }

    }

    a {
      line-height: $line-height-large;
      padding: 0;
    }
  }

  .caret {
    display: none;
  }
}

.region-footer-right {
  @include breakpoint(sm-max) {
    margin-top: $padding-large-vertical * 4;
  }

  ul {
    opacity: .9;

    > li {
      @include breakpoint(sm-max) {
        margin-bottom: $padding-small-vertical * 3;
      }

      > a {
        padding: 0;
      }
    }
  }
}
