.block--views-sisteme-electra-homepage {
  background-color: $gray-650;

  @include breakpoint(sm-max) {
    padding: $padding-large-vertical * 3 0;
  }

  @include breakpoint(md) {
    padding: $padding-large-vertical * 8 0 $padding-large-vertical * 10;
    position: relative;
  }

  h2 {
    margin: 0 0 50px;
    text-align: center;
  }

  .container {
    position: relative;
  }

  .dot {
    @include breakpoint(md) {
      &::before {
        background-color: $brand-primary;
        border-radius: 100%;
        content: '';
        display: block;
        height: 60px;
        left: 15px;
        position: absolute;
        top: -110px;
        width: 60px;
      }
    }
  }
}
