.node-product {
  &.node-full {
    .row {
      &:first-child {
        > div {
          padding: $padding-large-vertical * 5;
        }
      }
    }

    .col-sm-5 {
      @include breakpoint(sm) {
        justify-content: space-between;
      }
    }

    .model {
      display: none;
      color: $gray-150;
      margin: 0;
    }

    .model-mobile .model {
      @include breakpoint(xs) {
        display: block;
        margin: $padding-large-vertical * 2 0 $padding-large-vertical * 3;
        text-align: center;
      }
    }

    .field-type-image {
      padding: $padding-large-vertical * 7.5 0;
      text-align: center;

      + .model {
        @include breakpoint(sm) {
          display: block;
        }
      }
    }

    .col-sm-7 {
      @include breakpoint(md) {
        align-items: flex-start;
        flex-direction: row-reverse;
      }
    }

    .buy-section {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 0 $padding-small-horizontal * 2;

      @include breakpoint(md) {
        background-color: $gray-650;
        margin-left: $padding-small-horizontal * 2;
        min-width: 280px;
        padding: $padding-small-horizontal * 3 $padding-large-vertical * 4;
      }

      .sell-price {
        color: $gray-100;
        font-size: $font-size-base * 1.875;
        font-weight: bold;
        margin-right: 5px;
      }

      .tva {
        color: $gray-500;
        display: block;
        font-size: $font-size-medium;
        text-align: center;
      }

      .add-to-cart {
        width: 100%;
      }

      .form-actions {
        margin: 5px 0 0;
      }

      input {
        @include red-button;

        display: block;
        margin: 0;
        width: 100%;
      }
    }

    h2 {
      text-align: center;

      @include breakpoint(xs) {
        margin: $padding-large-vertical * 3 0 $padding-large-vertical * 2;
      }

      @include breakpoint(sm) {
        margin: $padding-large-vertical * 9 0 $padding-large-vertical * 5;
      }
    }
  }

  &.node-teaser {
    text-align: center;

    .field-name-uc-product-image {
      align-items: center;
      display: flex;
      height: 250px;
      justify-content: center;
    }

    h3 {
      margin-top: $padding-small-vertical * 2;

      @include breakpoint(sm-max) {
        font-size: $font-size-base;
      }

      @include breakpoint(md) {
        font-size: $font-size-medium;
        height: 80px;
      }
    }

    a {
      color: $gray-200;
    }

    .uc-price {
      font-size: $font-size-large;
      font-weight: bold;
    }
  }
}
