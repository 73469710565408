.after-content-2cols {
  background-color: $gray-650;

  @include breakpoint(sm-max) {
    padding: $padding-large-vertical * 3 0;
  }

  @include breakpoint(md) {
    padding: $padding-large-vertical * 10 0;
  }

  h2 {
    margin: 0;
  }
}

.after-content-shape {
  margin-top: -130px;
  min-height: 329px;

  @include breakpoint(sm-max) {
    display: none;
  }

  img {
    max-width: 100%;
  }
}

.after-content-phone {
  margin-top: -430px;
  min-height: 356px;

  @include breakpoint(sm-max) {
    display: none;
  }

  &-mobile {
    @include breakpoint(sm-max) {
      margin: $padding-large-vertical * 4 0 $padding-large-vertical * 3;
    }

    @include breakpoint(md) {
      display: none;
    }
  }

  &,
  &-mobile {
    img {
      max-width: 100%;
    }
  }
}
