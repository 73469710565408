.page--product--full {
  h1 {
    margin: 0 0 8px;

    @include breakpoint(xs) {
      font-size: $font-size-h3;
    }

    @include breakpoint(sm) {
      font-size: $font-size-h2;
    }
  }
}
