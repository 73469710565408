.about-us-banner {
  color: $body-bg;

  @include breakpoint(md) {
    margin-bottom: $padding-large-vertical * 9;
  }

  .container {
    @include breakpoint(md) {
      padding-left: 15px;
    }
  }

  .about-us-banner-container {
    @include breakpoint(md) {
      align-items: center;
      background-image: url('/sites/all/themes/electra_radix/assets/images/layout/about-us/banner.jpg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      display: flex;
      margin-left: auto;
      max-width: calc(50% + 1262px / 2 - 15px);
      min-height: 626px;
    }
  }

  .container {
    @include breakpoint(md) {
      margin-left: 0;
    }
  }

  .col-md-6 {
    @include breakpoint(sm-max) {
      padding-left: 0;
      padding-right: 0;
    }

    @include breakpoint(md) {
      display: none;
    }
  }

  .col-md-4 {
    background-color: $brand-primary;

    @include breakpoint(sm-max) {
      padding-bottom: $padding-large-vertical * 4;
      padding-top: $padding-large-vertical * 3;
    }

    @include breakpoint(md) {
      padding: $padding-large-vertical * 4.5 $padding-small-horizontal * 5.5 $padding-large-vertical * 6;
    }
  }

  img {
    max-width: 100%;

    @include breakpoint(md) {
      display: none;
    }
  }

  h1 {
    margin: 0 0 $padding-large-vertical * 1.5;
  }

  p {
    font-size: $font-size-medium;
    margin-bottom: 0;
    opacity: .9;

    @include breakpoint(sm-max) {
      max-width: 90%;
    }
  }
}
