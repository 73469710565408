@mixin round-button() {
  border-radius: 30px;
  font-weight: bold;

  @include breakpoint(sm-max) {
    padding: $padding-base-vertical * 2 $padding-small-horizontal * 2;
  }

  @include breakpoint(md) {
    padding: $padding-base-vertical * 3 $padding-small-horizontal * 3;
  }
}

@mixin red-button() {
  @include round-button;
}

@mixin gray-button() {
  @include round-button;

  background-color: $gray-700;
  color: $gray-300;

  &:hover {
    background-color: $gray-lighter;
  }
}
