.form-item {
  margin-bottom: $padding-extra-large-vertical;

  label {
    font-size: $font-size-medium;
    font-weight: normal;
    margin-bottom: 9px;
  }

  .form-control {
    background-color: $body-bg;
    border-radius: 0;
    border-width: 0;
    box-shadow: none;
    color: $gray-400;
    font-size: $font-size-medium;
    height: auto;
    padding: $padding-large-vertical $padding-small-horizontal * 2;

    &:focus {
      box-shadow: none;
    }
  }
}

.form-submit {
  @include red-button();
}

.error {
  color: $brand-primary;
}
