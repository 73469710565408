.about-us {
  .row {
    @include breakpoint(sm-max) {
      flex-direction: column-reverse;
      margin-top: $padding-large-vertical * 2;
    }

    @include breakpoint(md) {
      align-items: center;
      justify-content: space-between;
      margin-top: $padding-large-vertical * 6;
      padding: 0 $padding-small-horizontal * 10;
    }

    &:nth-child(2n + 1) {
      @include breakpoint(md) {
        flex-direction: row;
      }

      img {
        @include breakpoint(md) {
          padding: 0 $padding-small-horizontal * 10 0 0;
        }
      }
    }

    &:nth-child(2n + 2) {
      @include breakpoint(md) {
        flex-direction: row-reverse;
      }

      img {
        @include breakpoint(md) {
          padding: 0 0 0 $padding-small-horizontal * 10;
        }
      }
    }

    &:last-child {
      margin-bottom: $padding-large-vertical * 7.5;
    }
  }

  .col-md-6 {
    &:last-child {
      @include breakpoint(sm-max) {
        margin-bottom: $padding-large-vertical;
      }
    }
  }

  img {
    height: auto;
    max-width: 100%;
  }
}
