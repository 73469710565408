.block-ats-gps {
  h2 {
    color: $gray-150;
  }

  .google-map-address {
    color: $gray-400;
    font-size: $font-size-medium;
    line-height: $line-height-extra-large;
    margin: $padding-large-vertical * 4 0 $padding-large-vertical * 5;
    opacity: .9;
  }

  i {
    color: $brand-primary;
    font-size: $font-size-base;
    margin-right: $padding-large-horizontal;
  }

  strong {
    color: $gray-150;
    display: block;
    font-size: $font-size-large;
    margin: $padding-large-vertical * 4.5 0 $padding-large-vertical;
  }
}
