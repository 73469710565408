.breadcrumb {
  background-color: transparent;
  font-size: $font-size-small;
  margin: 0;
  padding: 0;

  .first,
  .delimiter {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1;
  }

  .first {
    &:before {
      content: '\f015';
    }

    a {
      font-size: 0;
    }
  }

  a {
    color: $gray-200;
  }

  .delimiter {
    font-size: 0;
    margin: 0 7px;

    &:before {
      font-size: $font-size-base * .625;
      content: '\f054';
    }
  }
}
