.blog-homepage {
  .title {
    display: flex;
    justify-content: space-between;

    @include breakpoint(sm-max) {
      align-items: center;
      margin-bottom: $padding-base-vertical * 4;
    }

    @include breakpoint(md) {
      margin-bottom: $padding-large-vertical * 2;
    }
  }

  i {
    background-color: $brand-primary;
    border-radius: 50%;
    color: $body-bg;
    height: 60px;
    line-height: 60px;
    text-align: center;
    width: 60px;
  }
}
