.comfort-shop {
  @include breakpoint(sm-max) {
    margin-bottom: 40px;
  }

  @include breakpoint(md) {
    margin-bottom: 145px;
  }

  .row {
    @include breakpoint(sm-max) {
      flex-direction: column-reverse;
    }
  }

  .col-md-5 {
    &:first-child {
      background-image: url(/sites/all/themes/electra_radix/assets/images/layout/interfon.png);
      background-position: bottom left;
      background-repeat: no-repeat;

      @include breakpoint(sm-max) {
        margin-top: $padding-large-vertical * 4;
        min-height: 420px;
        margin-left: 20px;
        margin-right: 20px;
      }

      @include breakpoint(md) {
        min-height: 488px;
      }
    }

    &:last-child {
      @include breakpoint(md) {
        background-image: url(/sites/all/themes/electra_radix/assets/images/layout/amortizor.jpg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        padding-top: 244px;
      }
    }
  }

  img {
    max-width: 100%;

    @include breakpoint(md) {
      display: none;
    }
  }

  p {
    color: $gray-400;
    font-size: $font-size-medium;
    margin: 16px 0 25px;
    opacity: .9;
  }

  a {
    @include red-button;
  }
}
