.page-node-82 {
  h1 {
    @include breakpoint(xs) {
      font-size: $font-size-base * 2;
      margin: $padding-large-vertical * 3 0 $padding-large-vertical * 4;
    }

    @include breakpoint(sm) {
      font-size: $font-size-h2;
      margin: 0 0 $padding-large-vertical * 5.5;
      text-align: center;
    }
  }

  [class^='step-'] {
    display: none;
  }

  &.calculator-step-1 .step-1 {
    display: block;
  }

  &.calculator-step-2 .step-2 {
    display: block;
  }

  .after-content-2cols {
    background-color: $body-bg;
    margin-top: -100px;

    @include breakpoint(md) {
      margin-top: 0;
    }

    .row {
      flex-direction: column-reverse;

      @include breakpoint(md) {
        flex-direction: row;
      }
    }
  }

  .after-content-shape {
    display: block;
    min-height: inherit;
    margin-left: -250px;
    margin-top: -65px;
    overflow: hidden;
    position: relative;

    @include breakpoint(md) {
      margin-left: 40%;
      margin-top: -93px;
    }

    img {
      max-width: 200%;

      @include breakpoint(md) {
        max-width: 100%;
      }
    }
  }

  .after-content-phone {
    display: block;
    margin-top: -50px;
    min-height: auto;

    @include breakpoint(md) {
      min-height: 356px;
    }
  }
}

.red-background {
  position: relative;

  &:before {
    @include breakpoint(md) {
      background-color: $brand-primary;
      bottom: 0;
      clip-path: polygon(0 80%, 100% 0, 100% 100%, 0 100%);
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
