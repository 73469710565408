body {
  color: $gray-200;
}

@include breakpoint(sm-max) {
  h1 {
    font-size: $font-size-large * 2;
  }

  h2 {
    font-size: $font-size-base * 2;
  }

  h4 {
    font-size: $font-size-base;
  }
}
